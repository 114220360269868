import React from "react";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import { Section, Text, Button, MainTitle, Title } from "../components/Core";
import TeamMembers from "./../sections/team-members/TeamMembers"
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../utils/helperFn";
import heroImg from "./../assets/image/jpeg/hero-tenants.jpg";
import tenantsAbout from "./../assets/image/jpeg/tenants-about.jpg";
import tenantsBackground from "./../assets/image/jpeg/tenants-background.jpg";
import separator from "./../assets/image/png/separator.jpg";
import { navigate } from 'gatsby';

import tenants1 from './../assets/image/svg/tenants-icon-1.svg';
import tenants2 from './../assets/image/svg/tenants-icon-2.svg';
import tenants3 from './../assets/image/svg/tenants-icon-3.svg';
import tenants4 from './../assets/image/svg/tenants-icon-4.svg';

import planning1 from './../assets/image/jpeg/planning-image-1.jpg';
import planning2 from './../assets/image/jpeg/planning-image-2.jpg';

import bottomMotive from './../assets/image/png/bottom-motive-white.png';

import fullWidthTenantsBackground from './../assets/image/jpeg/full-width-tenants.jpg';

import caretUp from './../assets/image/png/caret-up.png';
import caretDown from './../assets/image/png/caret-down.png';

import styled from "styled-components";

const WayleavesForTenants = () => {

    const AbsoluteTitle = styled(Title)`
        position: absolute;
        color: #fff;
        top: 120px;
        left: 15px;
        @media (min-width: 576px) { 
            top: 120px;
            left: calc((100vw - 525px) / 2);
        }
        @media (min-width: 768px) { 
            top: 120px;
            left: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            top: 120px;
            left: calc((100vw - 945px) / 2);
        }

        @media (min-width: 1200px) {
            top: 35%;
            left: calc((100vw - 1125px) / 2);
        }
    `;

    const SmallParagraph = styled(Text)`
        font-size: 18px;
        line-height: 30px;
    `;

    const PinkTitle = styled(Title)`
        color: #db118b;
        margin-top: 100px;
        text-align: left;
        @media (max-width: 991px) {
            margin-top: 20px;
        }
    `;

    const NarrowWrapper = styled.div`
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 0 100px 0;
        p {
            color: #fff;
            text-align: left;
            margin-bottom: 10px;
        }
        @media (max-width: 1400px) {
            width: 100%;
        }
        @media (max-width: 991px) {
            margin-bottom: 50px;
        }
        padding-left: 15px;
        @media (min-width: 576px) { 
            padding-left: calc((100vw - 525px) / 2);
            padding-right: calc((100vw - 525px) / 2);
            p {
                font-size: 28px;
            }
        }
        @media (min-width: 768px) { 
            padding-left: calc((100vw - 705px) / 2);
            padding-right: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            padding-left: calc((100vw - 945px) / 2);
            padding-right: calc((100vw - 945px) / 2);
        }

        @media (max-width: 991px) { 
            padding-top: 0;
            padding-bottom: 40px;
        }

        @media (min-width: 1200px) {
            padding-left: calc((100vw - 1125px) / 2);
            padding-right: 0;
        }
    `;

    const Box = styled.div`
        background-color: #fff;
        padding: 50px;
        border-radius: 50px;
        box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.20);
        @media (max-width: 991px) {
            padding: 25px;
            .first-img {
                margin-bottom: 25px;
            }
            .second-img {
                margin-top: 25px;
            }
        }
    `;

    const NarrowBox = styled(Box)`
        max-width: 600px;
        display: block;
        margin: 100px auto;
        text-align: center;
        @media (max-width: 991px) {
            margin: 0 auto;
        }
    `;

    const StyledImage = styled.img`
        border-radius: 20px;
        width: 100%;
    `;

    const StyledSubtitle = styled.h2`
        color: #000;
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 1;
        letter-spacing: -0.56px;
    `;

    const StyledSection = styled(Section)`
        
    `;

    const GradientSection = styled(Section)`
        position: relative;
        background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
        h2, p, a {
            color: #fff !important;
        }
        .container {
            position: relative;
        }
    `;

    const AbsoluteImage = styled.img`
        position: absolute;
        bottom: 0;
        right: 0;
    `;

    const StyledAccordion = styled(Accordion)`
        .accordion-item {
            border: none;
            border-bottom: 1px solid #ce178a !important;
        }
        .accordion-button {
            
        }
        .accordion-header .accordion-button {
            text-transform: unset;
            font-size: 1.25rem;
            padding-left: 0;
            padding-right: 0;
            background-color: transparent;
            color: #000;
        }
        .accordion-body {
            padding: 0 0 20px 0;
        }
        .accordion-button::after {
            background-image: url(${caretDown});
        }
        .accordion-item:last-of-type,
        .accordion-item:last-of-type .accordion-button {
            border-radius: 0 !important;
        }
    `;

    const BgSection = styled(Section)`
        background: url(${tenantsBackground});
        background-size: cover;
        background-repear: no-repeat;
    `;
    
    const FullWidthSection = styled(Section)`
        background: url(${fullWidthTenantsBackground});
        background-size: cover;
        background-repear: no-repeat;
    `;

    const HeroImg = styled.img`
        filter: brightness(0.8);
    `;

    return (
        <>
            <Seo page="wayleaves" />
            <div className="hero-wayleaves">
                <HeroImg src={heroImg} className="w-100 mt-10" />
                <AbsoluteTitle>Wayleaves<br /> for tenants</AbsoluteTitle>
            </div>
            <PageWrapper>
                <Row className="justify-content-center text-center dark-bg">
                    <Col xl="6">
                        <NarrowWrapper>
                            <PinkTitle>What is a wayleave</PinkTitle>
                            <Text>
                                A wayleave is an agreement between the
                                freeholder and a supplier, in this case
                                Netomnia. This gives the supplier the right
                                to access the property and carry out pre
                                agreed works to install the supplier's
                                infrastructure. The Wayleave also allows the
                                supplier to maintain the infrastructure
                                under prior notice to the freeholder
                            </Text>
                            <Text>
                                Because you are a tenant, you will need
                                your freeholder’s permission to have full
                                fibre installed to the property.
                            </Text>
                            <Text>
                                A wayleave agreement will need to be in
                                place between your freeholder and
                                ourselves in order to connect your home to
                                our full fibre network.
                            </Text>
                        </NarrowWrapper>
                    </Col>
                    <Col xl="1"></Col>
                    <Col xl="5" className="flex-end">
                        <img src={tenantsAbout} className="w-100 first-img"/>
                    </Col>
                </Row>

                <Section className="background-light-pink">
                    <Container>
                        <Row>
                            <Col lg="5">
                                <Title variant="news">Why is a wayleave<br />important?</Title>
                                <SmallParagraph>
                                    To have our equipment installed within your home, we
                                    need the permission of the property owner saying that
                                    we are allowed to do so. In this case, the freeholder of
                                    your residence.
                                </SmallParagraph>
                                <SmallParagraph>
                                    Without the wayleave agreement in place, you will not
                                    be able to access our network.
                                </SmallParagraph>
                                <SmallParagraph>
                                    It is vital that if you have received a letter from us, you
                                    open the conversation with your landlord about having
                                    full fibre broadband installed.
                                </SmallParagraph>
                                <SmallParagraph>
                                    Alternatively, if you provide us with your freeholder’s
                                    details, we can have the conversation on your behalf.
                                </SmallParagraph>
                            </Col>
                            <Col lg="1"></Col>
                            <Col lg="6" className="centered">
                                <img src={planning1} className="w-100 border-radius-10"/>
                            </Col>
                        </Row>
                    </Container>
                </Section>

                <Section className="white-bg">
                    <Container>
                        <MainTitle className="text-center pink-color">Benefits of full fibre</MainTitle>
                        <Row className="justify-content-center text-center">
                            <Col lg="6" className="mt-5">
                                <img src={tenants1} alt="Netomnia Multiple devices" height={100}/>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        Multiple devices can be used
                                        simultaneously even during peak hours.
                                    </SmallParagraph>
                                </div>  
                            </Col>
                            <Col lg="6" className="mt-5">
                                <img src={tenants2} alt="Netomnia Full fibre" height={100}/>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        Full fibre provides excellent download
                                        and upload speeds meaning working from
                                        home has never been easier as well as a
                                        host of other benefits.
                                    </SmallParagraph>
                                </div>
                            </Col>
                            <Col lg="6" className="mt-5">
                                <img src={tenants3} alt="Netomnia ISP partner" height={100}/>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        Our ISP partner YouFibre offers better
                                        value per Mbps in comparison to some
                                        of the household names. They offer up
                                        to 8,000Mbps for residential packages.
                                    </SmallParagraph>
                                </div>
                            </Col>
                            <Col lg="6" className="mt-5">
                                <img src={tenants4} alt="Netomnia Netomnia Planning" height={100}/>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        Effortless working from home experience,
                                        a household worth of devices streaming
                                        at once, hours of movies downloaded
                                        at the click of a button.
                                    </SmallParagraph>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Section>

                <BgSection>
                    <Container>
                        <Row>
                            <Col lg="6" className="centered">
                                <img src={planning2} className="w-100 border-radius-10"/>
                            </Col>
                            <Col lg="6">
                                <Title variant="news">
                                    What information do<br/>
                                    you need to have for<br/>
                                    your wayleave
                                </Title>
                                <SmallParagraph>
                                    When it comes to the wayleave agreement, the only
                                    thing you need to do is express your desire in having full
                                    fibre broadband installed to the freeholder of your home.
                                </SmallParagraph>
                                <SmallParagraph>
                                    Open the conversation with your landlord/managing
                                    agent and say that you want to upgrade to a full fibre
                                    broadband connection.
                                </SmallParagraph>
                                <SmallParagraph>
                                    Alternatively, provide us with your landlord's or
                                    managing agent's details and we can open the
                                    conversation on your behalf!
                                </SmallParagraph>
                            </Col>
                        </Row>
                    </Container>
                </BgSection>

                <FullWidthSection>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <NarrowBox>
                                    <Title variant="news">What happens next?</Title>
                                    <SmallParagraph>
                                        Once permission has been granted, you can order your
                                        broadband package through our ISP partners.
                                    </SmallParagraph>
                                    <SmallParagraph>
                                        They will agree an installation date with you and once
                                        agreed, the installation team will arrive and install your
                                        broadband service just like any other!
                                    </SmallParagraph>
                                </NarrowBox>
                            </Col>
                        </Row>
                    </Container>
                </FullWidthSection>

                <GradientSection>
                    <AbsoluteImage src={bottomMotive} />
                    <Container>
                        <Row>
                            <Col lg="12" className="text-center">
                                <Title className="centered">Want full fibre broadband?</Title>
                                <Text className="w-80 centered">
                                    Email <a href="mailto:wayleaves@netomnia.com">wayleaves@netomnia.com</a> with your
                                    freeholder’s details and we can action on your behalf.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </GradientSection>

            </PageWrapper>
        </>
    )
}

export default WayleavesForTenants;
